/* // XX-Large devices (larger desktops, 1400px and up) */

.gridContainer {
  padding: 50px 180px;
}

@media (min-width: 2000px) {
  .gorrito-navidad {
    left: 112vh;
    top: 22vh;
  }

  .height--header {
    height: calc(100vh - 104px);
  }

  .opcionesUsuario {
    margin-left: 66%;
    top: 13%;
  }
}
@media (max-width: 2560px) {
  .responsiveDes {
    width: max-content;
  }

  .gorrito-navidad {
    left: 113vh;
    top: 22vh;
  }
}

@media (max-width: 1440px) {
  .gorrito-navidad {
    left: 90vh;
    top: 22vh;
  }

  .responsiveDes {
    width: auto;
  }

  .height--header {
    height: calc(100vh - 104px);
  }

  .cajaServicios {
    font-size: 13px;
  }

  .cajaServiciosActive {
    font-size: 13px;
    left: 10%;
  }

  .FacturaValidacion {
    width: 42%;
  }
}

@media (max-width: 1100px) {
  .cajaServiciosActive {
    font-size: 13px;
    left: 5%;
  }
}

@media (max-width: 1025px) {
  .gorrito-navidad {
    left: 68vh;
    top: 22vh;
  }

  .height--header {
    height: calc(100vh - 104px);
  }

  .cajaServicios {
    font-size: 13px;
  }

  .cajaServiciosActive {
    font-size: 13px;
    left: 0%;
  }

  .FacturaValidacion {
    width: 59% !important;
    left: 20%;
  }

  /* .FacturaValidacionActive {
        border: 1px solid red;
        width: 59% !important;
        left: 20%;
    } */
}

@media (max-width: 770px) {
  .gorrito-navidad {
    left: 59vh;
    top: 17vh;
  }

  .responsiveDes {
    width: auto;
  }

  .height--header {
    height: calc(100vh - 104px);
  }

  .cajaServicios {
    font-size: 13px;
  }

  .cajaServiciosActive {
    font-size: 13px;
  }

  .FacturaValidacion {
    width: 78% !important;
    left: 11%;
  }

  /* .FacturaValidacionActive {
        width: 78% !important;
        left: 11%;
    } */
}

@media (max-width: 1400px) {
  .gorrito-navidad {
    left: 112vh;
    top: 22vh;
  }
  .height--header {
    height: calc(100vh - 104px);
  }

  .cajaServicios {
    font-size: 13px;
  }

  .cajaServiciosActive {
    font-size: 13px;
  }

  .FacturaValidacion {
    width: 42%;
  }

  /* .FacturaValidacionActive {
        width: 42%;
    } */
}

/* // X-Large devices (large desktops, 1200px and up) */

@media (max-width: 1200px) {
  .gorrito-navidad {
    left: 112vh;
    top: 22vh;
  }
  .height--header {
    height: calc(100vh - 104px);
  }

  .CardVerificacionDoc {
    margin-left: 3%;
  }

  .paddinTopMenu {
    padding-top: 5px;
  }
}

/* // Large devices (desktops, 992px and up) */

@media (max-width: 992px) {
  .width50 {
    width: 100%;
  }

  .heightViewport {
    height: 100%;
  }

  .height--header {
    height: calc(100vh - 104px);
  }

  .height100 {
    height: auto;
  }

  .tableHeadItem {
    width: 100% !important;
  }

  .tableHead {
    width: 100%;
    padding: 10px 10px 0 0;
    text-align: justify;
  }

  .w120px {
    width: 100%;
  }

  .cardAnimate {
    width: 100%;
  }

  .marginleft2rem {
    margin-left: 2rem;
  }

  .avatarUsuario {
    display: flex;
    position: absolute;
    top: 26%;
    width: 250px;
    left: 69%;
  }

  .opcionesUsuario {
    margin-left: 63%;
    top: 6%;
  }

  .modalVerificarCertificado {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    max-height: 450px;
    background-color: #ffffff;
    border-radius: 30px;
    box-shadow: 24px;
    padding: 4%;
    /* p: 4; */
  }

  .footer_responsive {
    display: block !important;
  }
  .mensajeGobiernoNormal {
    display: none;
  }
}

@media (max-width: 900px) {
  .responsiveDes {
    width: auto;
  }

  .footer_responsive {
    display: block !important;
  }
  .mensajeGobiernoNormal {
    display: none;
  }
  .wizard {
    top: 75%;
    width: 95%;
  }
}

.menuServiciosMovil {
  display: none;
}

.ServiciosCardMovil {
  display: none;
}

.ServiciosCardEscritorio {
  display: block;
}

.imgServiciosvalidacion {
  width: 60%;
}

.imgServiciosfactura {
  width: 50%;
}

.TarjetaTramitesMovil {
  display: none;
}

.TarjetaTramitesEscritorio {
  display: block;
}

.usuariobutton {
  display: none !important;
}

/* // Large devices iphone 12 pro (desktops, 992px and up) */

@media (max-width: 849px) {
  .responsiveDes {
    width: auto;
  }

  .footer_responsive {
    display: block !important;
  }
  .mensajeGobiernoNormal {
    display: none;
  }

  .ModalServicios {
    top: 21%;
    /* left: 42%; */
    width: 78%;
    height: 300px;
  }

  .usuariobutton {
    display: block !important;
    font-size: inherit;
  }

  .menuInicioSesion {
    display: block;
  }

  .TarjetaTramitesMovil {
    display: block;
  }

  .TarjetaTramitesEscritorio {
    display: none;
  }

  .nuevodiseñoServiciosCard {
    margin-top: 3%;
    max-height: 250px !important;
    min-height: 200px !important;
    min-width: 200px !important;
    max-width: 250px;
  }

  .imgServiciosvalidacion {
    width: 86%;
  }

  .imgServiciosfactura {
    width: 68%;
  }

  .ServiciosCardMovil {
    display: block;
  }

  .ServiciosCardEscritorio {
    display: none;
  }

  .cajaServiciosActive {
    top: 19%;
    left: 36%;
  }

  .cajaServicios {
    top: 19%;
    left: 36%;
  }

  .menuServiciosescritorio {
    display: none !important;
  }

  .menuServiciosMovil {
    display: flex !important;
    color: white;
  }

  .adminview {
    height: 241px;
  }

  .opcionesUsuario {
    position: absolute;
    margin-left: 60%;
    top: 9%;
  }

  /* .ModalServicios {
        top: 35%;
        left: 42%;
        width: 83%;
    } */

  .HeaderResponsiveText {
    font-size: 12px;
  }

  .HeaderResponsivelogoMicht {
    width: 140px;
  }

  .HeaderResponsivelogoGD {
    width: 50px;
  }

  .btnLoginHeader {
    display: none;
  }

  .labelusuario {
    display: none;
  }

  /* .wizard {
        top: 60%;
    } */
}

/* // Medium devices (tablets, 768px and up) */

@media (max-width: 800px) {
  .responsiveDes {
    width: auto;
  }

  .footer_responsive {
    display: block !important;
  }
  .mensajeGobiernoNormal {
    display: none;
  }
  .LabelBienenidoPublic {
    margin-top: 0rem !important;
  }

  .height--header {
    height: calc(100vh - 104px);
  }

  .gridContainer {
    padding: 0px !important;
  }

  /* .margin-on-search {
    margin-top: 200px !important;
  } */

  .adminlineaCaptura {
    padding-left: 4px;
  }

  .botonesAdmin {
    justify-content: center;
    display: flex;
  }

  .adminview {
    height: 840px;
  }

  .botonusuariosadmin {
    width: 232px;
  }

  .wizard {
    top: 80%;
    width: 95%;
  }
  /* .img-man {
    width: 80%;
  } */
}

@media (min-width: 600px) {
  .adminlineaCaptura {
    padding-left: 4px !important;
  }

  .botonesAdmin {
    justify-content: center;
    display: flex;
  }

  /* .adminview {
        height: 840px;
    } */

  /* .wizard{
        top: 100%;
    } */
}

@media (max-width: 576px) {
  .footer_responsive {
    display: none !important;
  }
  .mensajeGobiernoNormal {
    display: none !important;
  }
  .height--header {
    height: 100%;
  }

  .redes {
    width: 100%;
  }

  /* .margin-on-search {
    margin-top: 200px !important;
  } */

  .adminview {
    height: 137px;
  }

  .wizard {
    top: 85%;
    width: 95%;
  }

  .css-zow5z4-MuiGrid-root > .MuiGrid-item {
    text-align: left !important;
  }

  .avatarUsuario {
    display: flex;
    position: absolute;
    top: 16%;
    right: 9%;
    width: 155px;
  }

  .usuariobutton {
    font-size: inherit;
  }

  .labelusuario {
    display: none;
  }

  .opcionesUsuario {
    position: absolute;
    margin-left: 38%;
    top: 10%;
  }

  .validarDocumentosBanner {
    display: none;
  }
}

/* Celulares 425px */

@media (max-width: 450px) {
  .gorrito-navidad {
    left: 37vh;
    top: 19vh;
  }

  .footer_responsive {
    display: none !important;
  }
  .mensajeGobiernoNormal {
    display: none !important;
  }

  .LoginWelcomeLabelPhone {
    display: block;
  }

  .LoginWelcomeLabel {
    display: none;
  }

  .opcionesUsuario {
    margin-left: 42%;
    top: 9%;
  }

  .avatarUsuario {
    top: 19%;
    right: 0%;
    left: 83%;
    width: 70px;
  }

  .modalVerificarCertificado:after {
    transform: scale(0.2);
  }

  .modalVerificarCertificado {
    width: 95%;

    overflow: hidden;
    overflow-y: scroll;
    padding: 4%;
  }
}

@media (max-width: 300px) {
  .footer_responsive {
    display: block !important;
  }
  .mensajeGobiernoNormal {
    display: none !important;
  }
  .opcionesUsuario {
    margin-left: 13%;
    top: 15%;
  }

  .avatarUsuario {
    top: 19%;
    right: 0%;
    left: 83%;
    width: 50px;
  }
}

@media (max-width: 390px) {
  .gorrito-navidad {
    left: 32vh;
    top: 19vh;
  }

  .sizeletrasgobTitle {
    font-size: 40px;
  }

  .sizeletrasgobsubtitle {
    font-size: 22px;
  }

  .footer_responsive {
    display: none !important;
  }
  .mensajeGobiernoNormal {
    display: none !important;
  }
  .opcionesUsuario {
    margin-left: 37%;
    top: 13%;
  }

  .avatarUsuario {
    top: 19%;
    right: 0%;
    left: 83%;
    width: 50px;
  }
}

/* // Height */

@media (max-height: 700px) {
  .height--header {
    height: 100%;
  }
}

@media (max-height: 550px) {
  .wizard {
    top: 100%;
  }
}

@media (max-height: 500px) {
  .wizard {
    top: 140%;
  }
}

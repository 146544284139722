/*------------------------------ Css General----------------------- */

@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Gibson-Bold";
  src: url("../assets/fonts/Gibson-Bold.otf");
}

@font-face {
  font-family: "Gibson-BoldItalic";
  src: url("../assets/fonts/Gibson-BoldItalic.otf");
}

@font-face {
  font-family: "Gibson-Book";
  src: url("../assets/fonts/Gibson-Book.otf");
}

@font-face {
  font-family: "Gibson-BookItalic";
  src: url("../assets/fonts/Gibson-BookItalic.otf");
}

@font-face {
  font-family: "Gibson-Heavy";
  src: url("../assets/fonts/Gibson-Heavy.otf");
}

@font-face {
  font-family: "Gibson-HeavyItalic";
  src: url("../assets/fonts/Gibson-Heavy.otf");
}

@font-face {
  font-family: "Gibson-Italic";
  src: url("../assets/fonts/Gibson-Italic.otf");
}

@font-face {
  font-family: "Gibson-Light";
  src: url("../assets/fonts/Gibson-Light.otf");
}

@font-face {
  font-family: "Gibson-LightItalic";
  src: url("../assets/fonts/Gibson-LightItalic.otf");
}

@font-face {
  font-family: "Gibson-Medium";
  src: url("../assets/fonts/Gibson-Medium.otf");
}

@font-face {
  font-family: "Gibson-MediumItalic";
  src: url("../assets/fonts/Gibson-MediumItalic.otf");
}

@font-face {
  font-family: "Gibson-Regular";
  src: url("../assets/fonts/Gibson-Regular.otf");
}

@font-face {
  font-family: "Gibson-SemiBold";
  src: url("../assets/fonts/Gibson-SemiBold.otf");
}

@font-face {
  font-family: "Gibson-SemiBoldItalic";
  src: url("../assets/fonts/Gibson-SemiBoldItalic.otf");
}

@font-face {
  font-family: "Gibson-Thin";
  src: url("../assets/fonts/Gibson-Thin.otf");
}

@font-face {
  font-family: "Gibson-ThinItalic";
  src: url("../assets/fonts/Gibson-ThinItalic.otf");
}

@font-face {
  font-family: "Open-Sans";
  src: url("../assets/fonts/OpenSans-Regular.ttf");
}

:root {
  --red: #dc3545;
  /* --red-bg: #ec45566b; */
  --red-bg: #8d949e;
  --white: #ffffff;
  --black: #000000;
  --blue: #1946bb;
  --light-blue: #4b73f0;
  /* --guinda: #6A0F49; */
  --guinda: #8d949e;
  /* --guindaClaro: #6A0F49; */
  --guindaClaro: #bec3c9;
  /* --guindagob: #993367; */
  --guindagob: #ccd0d5;
  /* --rosaClaro: #FFE6EC; */
  --rosaClaro: #ffc3d0;
  --rosaAlert: #ffe6ec;
  --morado: #8d949e;
  /* --morado: #6A0F49; */
  --orage: #fdde80;
  --warning: #f9e8c6;
  --light-orange: #ffaf17;
  --green: #d4eddb;
  --green-text: #2a633a;
  --primary-text: #050505;
  --secondary-text: #65676b;
  --input-gray: #f0f2f5;
  --gray-00: #e6e8eb;
  --gray-05: #f2f3f5;
  --gray-10: #ebedf0;
  --gray-20: #dadde1;
  --gray-25: #ccd0d5;
  --gray-30: #bec3c9;
  --gray-45: #8d949e;
  --gray-50: #e6e5e9;
  --gray-70: #606770;
  --gray-80: #444950;
  --gray-90: #303338;
  --gray-100: #1c1e21;
  --gray-gob: #6d807f;
  --lightblue-alpha: rgba(75, 115, 240, 0.1);
  --black-alpha-05: rgba(0, 0, 0, 0.05);
  --black-alpha-10: rgba(0, 0, 0, 0.1);
  --black-alpha-15: rgba(0, 0, 0, 0.15);
  --black-alpha-20: rgba(0, 0, 0, 0.2);
  --black-alpha-30: rgba(0, 0, 0, 0.3);
  --black-alpha-40: rgba(0, 0, 0, 0.4);
  --black-alpha-50: rgba(0, 0, 0, 0.5);
  --black-alpha-60: rgba(0, 0, 0, 0.6);
  --black-alpha-80: rgba(0, 0, 0, 0.8);
  --overlay-alpha-80: rgba(244, 244, 244, 0.8);
  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);
  --shadow-8: rgba(0, 0, 0, 0.8);
  --animate-duration: 0.7s;
  --primary: #007bff;
  --success: #28a745;
  --danger: #dc3545;
  --warning: #ffc107;
  --white-smoke: #f5f5f5;

  /* Fluid texts */
  --step--2: clamp(0.7rem, calc(0.87rem - 0.14vw), 0.84rem);
  --step--1: clamp(0.94rem, calc(0.95rem - 0.01vw), 0.94rem);
  --step-0: clamp(1.06rem, calc(1.03rem + 0.19vw), 1.25rem);
  --step-1: clamp(1.2rem, calc(1.1rem + 0.47vw), 1.67rem);
  --step-2: clamp(1.35rem, calc(1.17rem + 0.88vw), 2.22rem);
  --step-3: clamp(1.51rem, calc(1.22rem + 1.45vw), 2.96rem);
  --step-4: clamp(1.7rem, calc(1.25rem + 2.25vw), 3.95rem);
  --step-5: clamp(1.91rem, calc(1.25rem + 3.35vw), 5.26rem);
  --step-6: clamp(2.15rem, calc(1.18rem + 4.86vw), 7.01rem);

  /* NUEVOS COLORES */
  --main-color-vino: #6f1d46;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  /* border:1px solid red; */
  font-family: "Open-Sans";
  background-color: var(---gray-30);
  /* letter-spacing: 1.1px; */
}

html {
  overflow-x: hidden;
  font-family: "Open-Sans";
  /* letter-spacing: 1.1px; */
  font-weight: 500;
  /* overflow: scroll; */
}

body {
  margin: 0;
  overflow-x: hidden;
}

a,
a:link,
a:hover,
a:visited,
a:focus-visible {
  text-decoration: none;
  color: unset;
  border: none;
  outline: none;
  padding: 0;
}

.height--header {
  height: calc(100vh - 104px);
}

li {
  list-style: none;
}

.width50 {
  width: 50%;
}

.heightViewport {
  height: 100vh;
}

/*------------------------------ Menu----------------------- */

.boderBottom--Rosa {
  border-bottom: 5px solid var(--rosaClaro);
}

.borderBottom--guindaClaro {
  /* border-bottom: 5px solid var(--guindaClaro); */
  border-bottom: 5px solid var(--rosaClaro);
}

.nav-link {
  color: var(---gray-20) !important;
}

.logoGuinda {
  width: 30px;
}

.logo-header {
  width: 220px;
}

.logo-header-ventanilla {
  width: 80px;
}

.logo_menu {
  width: 100px;
}

.line-header {
  position: fixed;
  width: 100%;
  top: 6rem;
  z-index: 10 !important;
}

.itemNav-header {
  padding: 5px;
  cursor: pointer;
  color: var(--guinda);
}

.itemNav-header:hover {
  background-color: var(--rosaClaro);
  color: var(--guinda);
  border-radius: 5px;
  font-weight: 700;
}

.itemDropDown-header {
  padding: 5px !important;
  color: var(--guinda);
}

.itemDropDown-header:hover {
  cursor: pointer;
  color: var(--guinda);
  font-weight: 700;
  background-color: var(--rosaClaro);
}

.dropdown {
  padding: 5px;
  border-radius: 5px;
  color: var(--guinda);
}

.dropdown:hover {
  color: var(--guinda);
  font-weight: 700;
  background-color: var(--rosaClaro);
}

.bgHoverLink {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.bgHoverLink:hover {
  background-color: var(--guinda);
  color: white;
}

.menu {
  padding: 10px 15px;
  background-color: var(--white);
  border-right: 1px solid var(--gray-20);
  display: flex;
  flex-direction: column;
}

.menu_item {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 10px;
  color: var(--main-color-vino);
  font-family: "Montserrat";
  font-weight: 700;
}

.menu_item svg {
  font-size: 22px;
}

.menu_item:hover .badge {
  background-color: var(--white);
  color: var(--blue);
}

.menu_item:hover {
  background-color: var(--main-color-vino);
  color: white;
  stroke: white;
}

.menu_item:hover svg path {
  stroke: white;
}

.menu_item--disable {
  color: var(--gray-20);
  cursor: not-allowed;
}

.menu_item--disable:hover {
  background-color: var(--gray-45);
  color: white;
  stroke: white;
}

.menu_item .toggle {
  font-size: 15px;
}

.menu_item .toggle:hover {
  border-radius: 50%;
  background-color: white;
  color: var(--blue);
}

.brand {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 40px;
  padding-left: 10px;
  font-family: "Montserrat" !important;
  font-size: 1.32rem;
}

.brand img {
  width: 260px;
}

.border-top--guinda {
  border-top: 2px solid var(--main-color-vino);
}

.border-bottom--guinda {
  border-bottom: 2px solid var(--main-color-vino);
}

.border-bottom--rosa {
  border-bottom: 5px solid var(--rosaClaro);
}

.border-bottom--rosa1px {
  border-bottom: 1px solid var(--rosaAlert);
}

.enlace-login {
  font-size: 15px;
  text-decoration: underline;
  text-align: center;
  /* color: #6f1d46be; */
  color: var(--gray-45);
}

.enlace-login:hover {
  /* color: var(--main-color-vino); */
  color: #73767a;
  text-decoration: underline;
}

/*------------------------------ Footer ----------------------- */

.border-top--rosa {
  position: relative;
  padding-top: 10px;
}

.border-top--rosa::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--main-color-vino);
}

.border-top--rosa::after {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--rosaClaro);
}

.logoGris {
  width: 150px;
}

.logoSello {
  width: 80px;
}

.redes {
  width: 400px;
}

.backgrounPlaca {
  background-image: url("../assets/img/placa.png");
  background-size: cover;
  width: 100%;
  height: 40px;
}

/*------------------------------ Background ----------------------- */

.bg-white {
  background-color: var(--white);
}

.bg-guinda {
  background-color: var(--guinda);
}

.bg-guindaActas {
  background-color: var(--main-color-vino) !important;
  border-radius: 10px;
  padding: 5px;
  color: white;
}

.bg-armarillo {
  background-color: var(--warning);
}

.bg-morado {
  background-color: var(--morado);
}

.bg-grisClaro {
  background-color: var(--gray-50);
}

/* CAMBIA LOS COLORES DEL DATA TABLE */
.bg-rosaClaro {
  background-color: var(---gray-40);
}

.bg-rosaAlert {
  background-color: var(--rosaAlert);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-orange {
  background-color: var(--orage);
}

.bg-light-orange {
  background-color: var(--light-orange);
}

.bg-green {
  background-color: var(--green);
}

.bg-gray-00 {
  background-color: var(--gray-00);
}

.bg-gray-05 {
  background-color: var(--gray-05);
}

.bg-gray-10 {
  background-color: var(--gray-10);
}

.bg-gray-20 {
  background-color: var(--gray-20);
}

.bg-gray-25 {
  background-color: var(--gray-25);
}

.bg-gray-30 {
  background-color: var(--gray-30);
}

.bg-gray-45 {
  background-color: var(--gray-45);
}

.bg-gray-70 {
  background-color: var(--gray-70);
}

.bg-gray-80 {
  background-color: var(--gray-80);
}

.bg-gray-90 {
  background-color: var(--gray-90);
}

.bg-gray-90 {
  background-color: var(--gray-100);
}

.bg-image {
  background-image: url(../assets/img/fondoBlanco.png);
  background-size: cover;
}

.bg-fondo {
  background-image: url(../assets/img/fondo.png);
  background-repeat: no-repeat;
  /* background-position: 0px 100px; */
  background-size: cover;
  /* filter: opacity(0.9);
  -webkit-filter: opacity(0.9); */
}

.bg-red {
  background-color: var(--red-bg);
}

/*------------------------------ Textos Basado Tamaño ----------------------- */

.text-big {
  font-size: 1.62rem;
}

.text-medium {
  font-size: 1.18rem !important;
}

.text {
  font-size: 1rem !important;
}

.text-small {
  font-size: 13px;
}

.text-extra-small {
  font-size: 12px;
}

.text-super-small {
  font-size: 11px;
}

/*------------------------------ Texto primario y secundario ----------------------- */

.text-primary {
  color: var(--primary-text) !important;
}

.text-secondary {
  color: var(--secondary-text) !important;
}

/*------------------------------ Textos de tipo negritas  ----------------------- */

.text-bold {
  font-weight: 700;
}

.text-bolder {
  font-weight: 1000 !important;
}

/*------------------------------ textos de colores ----------------------- */

.text-footer {
  color: var(--gray-45) !important;
}

.text-guinda {
  /* color: var(--guinda) !important; */
  color: var(--main-color-vino) !important;
}

.text-vinoGob {
  /* color: var(--gray-gob) !important; */
  color: var(--main-color-vino) !important;
}

.text-morado {
  color: var(--morado);
}

.text-gray {
  color: var(--gray-45);
}

.text-gray90 {
  /* color: var(--gray-90); */
  color: #525659;
}

.text-light-blue {
  color: var(--light-blue);
}

.text-orange {
  color: var(--orage);
}

.text-light-orange {
  color: var(--light-orange);
}

.text-red {
  color: var(--red);
}

.text-blue {
  color: var(--blue);
}

.text-green {
  color: var(--green-text);
}

.font-light {
  font-weight: normal !important;
}

/*------------------------------ textos de colores ----------------------- */

.GibsonBold {
  font-family: "Gibson-Bold";
}

.GibsonBoldItalic {
  font-family: "Gibson-BoldItalic";
}

.GibsonBook {
  font-family: "Gibson-Book";
}

.GibsonBookItalic {
  font-family: "Gibson-BookItalic";
}

.GibsonHeavy {
  font-family: "Gibson-Heavy";
}

.GibsonHeavyItalic {
  font-family: "Gibson-HeavyItalic";
}

.GibsonItalic {
  font-family: "Gibson-Italic";
}

.GibsonLight {
  font-family: "Gibson-Light";
}

.GibsonLightItalic {
  font-family: "Gibson-LightItalic";
}

.GibsonMedium {
  font-family: "Gibson-Medium";
}

.GibsonMediumItalic {
  font-family: "Gibson-MediumItalic";
}

.GibsonRegular {
  font-family: "Gibson-Regular";
}

.GibsonSemiBold {
  font-family: "Gibson-SemiBold";
}

.GibsonSemiBoldItalic {
  font-family: "Gibson-SemiBoldItalic";
}

.GibsonThin {
  font-family: "Gibson-Thin";
}

.GibsonThinItalic {
  font-family: "Gibson-ThinItalic";
}

/*------------------------------ Botones ----------------------- */

.cta--subcategorias {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  padding: 10px 25px;
  border-radius: 10px;
  font-weight: 700;
  border: none;
}

.cta--subcategorias:hover {
  /* background-color: #4A001F; */
  background-color: #8d949e;
  color: white;
  transform: scale(1.005);
  box-shadow: 3px 3px 6px 0px var(--black-alpha-20);
}

.cta {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 25px;
  border-radius: 10px;
  /* font-family: Montserrat; */
  font-weight: 700;
  border: none;
  max-width: 380px;
}

.cta--icon {
  padding: 0px !important;
  max-width: 30px !important;
  height: 30px;
}

.cta:hover {
  transform: scale(1.005);
  box-shadow: 3px 3px 6px 0px var(--black-alpha-20);
}

.cta--light {
  width: 70px;
  height: 25px;
  font-size: 10px;
}

.cta--primary {
  /* background-color: var(--guindagob); */
  background-color: var(--main-color-vino);
  color: white;
}

.cta--secondary {
  background-color: var(--blue);
  color: white;
}

.cta--success {
  background-color: var(--success);
  color: white;
}

.cta--danger {
  background-color: var(--danger);
  color: white;
}

.cta--warning {
  background-color: var(--warning);
  color: white;
}

.cta--guinda {
  /* background-color: var(--guinda); */
  background-color: var(--main-color-vino);
  color: white;
}

.cta--guindagob {
  background-color: var(--guindagob);
  color: white;
}

.cta--rosa {
  background-color: var(--rosaClaro);
  color: var(--guinda);
}

.cta--green {
  background-color: var(--green);
  color: white;
}

.cta--red {
  background-color: var(--red);
  color: white;
}

.cta--purple {
  background-color: var(--morado);
  color: white;
}

.cta--disable {
  background-color: var(--gray-45);
  color: white;
  cursor: not-allowed;
}

.cta--short {
  width: 100px;
}

.pill {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--guinda);
  border-radius: 30px;
  padding: 10px 20px;
  background-color: var(--rosaAlert);
  color: var(--guinda);
  box-shadow: 3px 3px 3px 0px var(--black-alpha-10);
}

.pill:hover {
  /* border: 1px solid var(--); */
  background-color: var(--guinda);
  color: white !important;
}

.pill--active {
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: white !important;
}

.pill--disabled {
  border: 1px solid var(---gray-30);
  background-color: var(--gray-20);
  color: var(--gray-30) !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

.pill--disabled:hover {
  border: 1px solid var(---gray-30);
  background-color: var(--gray-20);
  color: var(--gray-30) !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

/*------------------------------ Form Input ----------------------- */

.form-input--searchIcon {
  background-color: var(--gray-00);
  padding: 10px;
  border-radius: 0px 20px 20px 0px;
  /* border: 1px solid red; */
}

.form-input--search {
  width: 100%;
  max-width: 350px;
  height: 45px;
  margin: 10px 0px;
  border: none;
  background-color: var(--gray-00);
  color: #65676b;
  outline: none;
  border-radius: 20px 0px 0px 20px;
  padding: 0px 15px;
  font-size: 14px;
}

.form-input {
  width: 100%;
  max-width: 380px;
  height: 45px;
  margin: 10px 0px;
  border: none;
  background-color: var(--gray-00);
  color: #65676b;
  outline: none;
  border-radius: 40px;
  padding: 0px 15px;
  font-size: 14px;
}

.form-input--error {
  border: 2px solid var(--orage);
  background-color: var(--orage);
}

.form-input:focus {
  border: 1px solid var(--guinda);
}

.form-input--full {
  max-width: inherit !important;
}

.form-input--short {
  width: 100px;
}

.form-input--disabled {
  border: 1px solid var(---gray-20);
  background-color: var(--gray-20);
  color: var(--gray-40) !important;
  cursor: not-allowed;
}

.form-input--disabled:hover {
  border: 1px solid var(---gray-20);
  background-color: var(--gray-20);
  color: var(--gray-40) !important;
  cursor: not-allowed;
}

.form-input--icon {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.form-input_icon {
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  background-color: var(--input-gray);
  padding-right: 15px;
}

/*------------------------------ GIF LOADER ----------------------- */

.container-gif {
  position: fixed;
  width: 100%;
  background-color: rgba(190, 195, 201, 0.7);
  height: 100%;
  z-index: 1400;
  top: 0;
  left: 0;
}

.logoGif {
  width: 300px;
}

.logoGiftView {
  width: 250px;
}

/*------------------------------ Dotloader de Colores ----------------------- */

.dottedloader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.dottedloader .dottedloader_dot:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.dottedloader .dottedloader_dot:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.dottedloader_dot {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  border-radius: 50%;
  -webkit-animation: dotjump;
  animation: dotjump;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.dottedloader--small .dottedloader_dot {
  width: 8px !important;
  height: 8px !important;
  margin-left: 1px;
  margin-right: 1px;
}

@-webkit-keyframes dotjump {
  0% {
    top: 0px;
  }

  50% {
    top: -8px;
  }

  100% {
    top: 0px;
  }
}

@keyframes dotjump {
  0% {
    top: 0px;
  }

  50% {
    top: -8px;
  }

  100% {
    top: 0px;
  }
}

.imagenHome {
  border-radius: 15px;
  width: 100%;
}

/*------------------------------ Tables ----------------------- */

.MuiFormLabel-colorPrimary {
  color: var(--morado) !important;
}

/* .MuiInputBase-colorPrimary{
    border-bottom: 2px solid var(--morado) !important;
} */

.css-1yhpg23-MuiTableCell-root {
  font-family: "Gibson-Regular" !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  display: none !important;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  display: none !important;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  display: none !important;
}

table {
  /* --bs-table-striped-bg: red; */
  color: var(--guinda);
}

.css-1ygcj2i-MuiTableCell-root {
  pointer-events: none;
  padding: 0px !important;
  font-weight: bolder !important;
}

/*------------------------------ Icon ----------------------- */

.icon_btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* color: #6f1d46b0; */
  color: #ccc;
  flex: none;
}

.icon_btn:hover {
  /* color: var(--main-color-vino); */
  color: var(--main-color-vino);
  z-index: 12 !important;
}

.icon_btn--big svg {
  font-size: 30px !important;
}

.icon_btn--small {
  width: 30px !important;
  height: 30px !important;
}

.icon_btn--rounded {
  border-radius: 50% !important;
}

.icon_btn--active {
  color: #1a47bc;
  background-color: var(--gray-00);
  border-bottom: none;
}

.icon_btn--disable {
  color: #ccc;
  cursor: not-allowed;
}

.icon_btn--disable:hover {
  background-color: inherit !important;
  color: #ccc !important;
}

.icon_btn:hover > .icon_btn_help,
.icon:hover > .icon_help {
  opacity: 1;
}

.icon_btn_help {
  text-align: center;
  white-space: nowrap;
  position: relative;
  opacity: 0;
  background-color: red;
  position: absolute;
  top: 35px;
  font-size: 10px;
  background-color: var(--main-color-vino);
  color: white;
  border-radius: 5px;
  line-height: 1.2;
  padding: 4px 8px;
  box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
  z-index: 1000 !important;
}

.icon_btn_help::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--main-color-vino);
  z-index: 1000 !important;
}

.icon_help {
  pointer-events: none;
  text-align: center;
  opacity: 0;
  background-color: red;
  position: absolute;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: -84px;
  width: 200px;
  font-size: 10px;
  background-color: var(--blue);
  color: white;
  border-radius: 5px;
  line-height: 1.2;
  padding: 6px 8px;
  box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  z-index: 1000 !important;
  cursor: default;
}

.icon_help::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--blue);
  z-index: 1000 !important;
}

/*------------------------------ Card ----------------------- */

.boxleft {
  width: 50px;
  height: 50px;
  border: 1px solid var(--main-color-vino);
  border-bottom: none;
  border-right: none;
}

.boxRight {
  width: 50px;
  height: 50px;
  border: 1px solid var(--main-color-vino);
  border-top: none;
  border-left: none;
}

.cardAnimate {
  border-radius: 10px;
  padding: 10px;
  height: 250px;
  width: 200px;
  background-color: white;
  box-shadow: 2.5px 2.5px 5px 0px var(--black-alpha-05);
}

.cardAnimate:hover {
  color: white;
  background-color: var(--main-color-vino);
}

.card {
  position: relative !important;
  /* width: 100%; */
  /* border: 1px solid var(--gray-10); */
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2.5px 2.5px 5px 0px var(--black-alpha-05);
}

.card-search {
  border-radius: 10px;
  padding: 10px;
  height: 250px;
  background-color: white;
  box-shadow: 2.5px 2.5px 5px 0px var(--black-alpha-05);
}

.card--with800 {
  width: 800px;
}

.card--with300 {
  width: 500px;
}

.card--height600 {
  height: 600px;
}

.card--rounded {
  border-radius: 30px !important;
  border: none;
}

.card--roundedRight {
  border-radius: 0px 30px 30px 0px !important;
}

/*------------------------------ PopUp ----------------------- */

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 640px;
  left: 0px;
  top: 0px;
  background-color: var(--black-alpha-30);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0.8px);
  z-index: 999;
  cursor: auto;
  overflow-y: hidden;
}

.overlay--transparent {
  background-color: transparent !important;
  backdrop-filter: none !important;
}

.overlay--mid {
  z-index: 10 !important;
}

.wizard {
  margin-top: 0px;
  width: 100%;
  max-width: 800px;
  max-height: 95%;
  background-color: var(--white);
  padding: 15px 30px;
  box-shadow: 3px 3px 12px 0px var(--shadow-2);
  border-radius: 10px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
  overflow-y: scroll;
}

/*------------------------------ Notificaciones ----------------------- */

.notificacion {
  position: relative;
  bottom: 10px;
  margin-bottom: 5px;
  z-index: 1024;
  background-color: var(--white);
  min-width: 360px;
  max-width: 480px;
  min-height: 80px;
  box-shadow: -20px 30px 50px 20px var(--black-alpha-05);
  padding: 10px 0px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.notificacion--success {
  border-left: 3px solid var(--green);
}

.notificacion--error {
  border-left: 3px solid red;
}

.notificacion--warning {
  border-left: 3px solid var(--light-orange);
}

.notificacion--option {
  border-left: 3px solid var(--morado);
}

.notificacion-animate {
  opacity: 1 !important;
  right: 10px !important;
}

.notification-animate-enter {
  opacity: 0;
  right: 0;
}

.notification-animate-enter-active {
  opacity: 1;
  right: 10px;
  transition: all 200ms;
}

.notification-animate-exit {
  opacity: 1;
  right: 10px;
}

.notification-animate-exit-active {
  opacity: 0;
  right: 0px;
  transition: all 200ms;
}

.notificacions {
  position: fixed;
  z-index: 1024;
  padding: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  bottom: 0px;
  right: 0px;
}

.mt-100 {
  margin-top: 100px;
}

.pdfview {
  margin-left: 25%;
  margin-bottom: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.to-top {
  scroll-behavior: smooth;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after,
.css-1a1fmpi-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #4b101f !important;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root.Mui-error:after,
.css-1a1fmpi-MuiInputBase-root-MuiInput-root.Mui-error:after {
  border-bottom: 2px solid #d32f2f !important;
}

.scrollItems {
  overflow-x: hidden !important;
  overflow: scroll;
}

.adminview {
  height: 1000px;
  /* background-color: red; */
}

.tableHead {
  width: 70%;
  /* border: 1px solid red; */
}

/* .btn-conceptos {
  background-color: rgb(221, 221, 221);
  border-radius: 50%;
} */

.tableHeadItem {
  width: 10% !important;
  /* border: 1px solid red; */
}

.carrito {
  position: fixed;
  top: 0;
  right: 0;
  width: 500px;
  background-color: white;
  height: 100vh;
  z-index: 100;
}

.cajeroview {
  margin-top: 30px;
  height: 65vh;
}

.addfolios {
  padding-left: 0px !important;
}

.boxfolios {
  padding-left: 0%;
}

.w120px {
  width: 120px;
}

.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  text-align: center !important;
}

.css-1ex1afd-MuiTableCell-root {
  text-align: center !important;
}

.css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon {
  display: none !important;
}

.css-1nf681w-MuiTableCell-root {
  border-top: 1px solid rgba(224, 224, 224, 1) !important;
}

.css-55oxen-MuiFormControl-root {
  min-width: 0px !important;
  text-align: center !important;
}

.css-zow5z4-MuiGrid-root > .MuiGrid-item {
  text-align: center !important;
}

.avatarUsuario {
  display: flex;
  position: absolute;
  top: 30%;
}

.opcionesUsuario {
  position: fixed !important;
  /* margin-left: 79%; */
  top: 3.5rem !important;
  background-color: #fff;
  /* z-index: 2; */
  /* border: 1px solid red; */
}

.css-1ex1afd-MuiTableCell-root {
  padding: 0px !important;
}

.tss-mwfche-MUIDataTableBodyRow-root {
  height: 60px !important;
}

.css-q34dxg {
  padding: 0px !important;
}

.css-2613qy-menu {
  display: flex;
  z-index: 1000;
}

/*------------------------------ Custom Scroll ----------------------- */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--guinda);
}

.swal-text {
  text-align: center !important;
}

.css-tqymag {
  display: none;
}

.Menu_lateral {
  position: fixed;
  top: 0;
  left: 0;
  width: 270px;
  height: 100vh;
  overflow: auto;
  z-index: 1000;
  padding: 10px 15px;
  background-color: var(--white);
  border-right: 1px solid var(--main-color-vino);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.css-zw3mfo-MufiModal-root-MuiDialog-root {
  z-index: 222 !important;
}

.content_title {
  background-color: var(--main-color-vino);
  color: white;
  padding: 8px;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
}

.subContainer {
  background-color: var(--rosaClaro);
  min-height: 250px;
  width: 85%;
  margin: 8px 0;
  border-radius: 8px;
  box-shadow: 1px 1px 10px 0px var(--black-alpha-20);
  display: flex;

  flex-direction: column;
  justify-content: center;
}

.css-12ssldz-MuiChip-root {
  border-radius: 10px !important;
}

th.MuiTableCell-root.MuiTableCell-head span button {
  margin: 0 auto;
}

button.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #a1647d;
  font-weight: 700;
}

.CardVerificacionDoc {
  max-width: 500px;
  margin-top: 2%;
  margin-left: 10%;
}

.modalVerificarCertificado {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 24px;
  padding: 4%;
  height: 90%;
  overflow: hidden;
  overflow-y: scroll;
}

.modalVerificarCertificado:after {
  content: url("/src/assets/img/Escudo_Michoacán.png");
  transform: scale(0.3);
  opacity: 0.2;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ChipsVerificar {
  min-width: 257px;
}

/* width */

::-webkit-scrollbar {
  width: 6px;
  height: 3px;
  cursor: pointer;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
}

/* Handle */

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--morado);
  cursor: pointer;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.componentesmuestra {
  margin-top: 5%;
}

.chipsOrdenProceso {
  margin-left: 1% !important;
}

.select-vistaprevia-config {
  width: 300px;
}

.FormularioDinamicoRadios {
  text-align: initial;
}

.FormularioDinamicoFormControl {
  width: 100%;
  margin-left: 2%;
}

.css-26l3qy-menu {
  z-index: 100000 !important;
}

.formDinamicoDescripc {
  margin-top: 2%;
}

.formDinamicoaire {
  margin-top: 2% !important;
}

.alertFormularioEncontrado {
  width: auto;
}

.Footerprint {
  display: none;
}

.linkPrint {
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}

@media print {
  .Footerprint {
    display: block;
  }

  .linkPrint {
    padding: 0 60px;
  }

  .modalVerificarCertificado {
    position: absolute;
    top: 5%;
    left: 0%;
    transform: translate(0%, 0%);
    width: 100%;
    background-color: #ffffff;
    border-radius: 0px;
    box-shadow: 0px;
    padding: 4%;
  }

  .btn-modalprint {
    display: none;
  }
}

.errorFormDinamicComponent {
  text-align: initial;
  color: #d32f2f;
  font-size: 13px;
}

.content_titleForm_dinamic {
  background-color: #ffffff !important;
  color: var(--main-color-vino) !important;
  /* color: #4a011f !important; */
  font-size: 42px;
  font-weight: bolder;
  font-family: sans-serif;
}

.LoginWelcomeLabelPhone {
  display: none;
}

.LoginWelcomeLabel {
  display: block;
}

.nuevodiseñoServiciosCard {
  /* background-color: #6f1f46 !important;
    background-image: linear-gradient(149deg, #6f1f46 0%, #ff9ecc 46%, #ffffff 100%); */
  background-color: var(--main-color-vino);
  /* background-image: linear-gradient(167deg, #6a1e41 2%, #ee80a8 38%, #ffffff 63%); */
  background-image: url("../assets/img/BotonBG.png");
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: center;
  margin-top: 3%;
  /* max-height: 250px !important;
  min-height: 250px !important; */
  min-width: 200px !important;
  border-radius: 30px !important;
}

.nuevodiseño {
  /* background-color: #6f1f46 !important;
    background-image: linear-gradient(149deg, #6f1f46 0%, #ff9ecc 46%, #ffffff 100%); */
  /* background-image: linear-gradient(167deg, #6a1e41 2%, #ee80a8 38%, #ffffff 63%); */
  /* background-image: url("../assets/img/BotonBG.png"); */
  background-image: url("../assets/img/BotonBG.png");
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: center;
  margin-top: 3%;
  /* max-height: 250px !important;
  min-height: 250px !important; */
  min-width: 200px !important;
  border-radius: 30px !important;
}

.bannerValidar {
  background-image: url("../assets/img/Banner.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 200px;
  cursor: pointer;
}

.sombrasprincipal {
  box-shadow: 4px 9px 4px 3px rgba(0, 0, 0, 0.38) !important;
  -webkit-box-shadow: 4px 9px 4px 3px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 4px 9px 4px 3px rgba(0, 0, 0, 0.38) !important;
}

/* Contenedor del popup */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* pop-up actual */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Muestra del Pop-up*/
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Cambio para mostrar/ocultar el contenedor del pop-up */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Animación del pop-up */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.botonDescargaArchivoCedula {
  display: block;
  border: 1px solid;
  background-color: var(--main-color-vino);
  text-align: center;
  width: 106px;
  color: white !important;
  border-radius: 9px;
}

.FacturaValidacion {
  top: -3.5rem;
  width: 40%;
  left: 30%;
  position: relative;
  height: 54px;
  box-sizing: border-box;
  /* border-radius: 0px 0px 11px 11px; */
  /* background: #6A0F49; */
  background: var(--main-color-vino);
  /* background-color: green; */
  cursor: pointer;
  z-index: 996 !important;
}

.PuntaIzquierda {
  background: var(--main-color-vino);
  /* background-color: red; */
  right: 99.5%;
  top: 1%;
  position: absolute;
  width: 25%;
  height: 47.5px;
  clip-path: polygon(100% 0, 100% 100%, 31% 0);
}

.PuntaIzquierdaFondo {
  background: var(--rosaClaro);
  /* background-color: red; */
  right: 99.79%;
  top: 10%;
  position: absolute;
  width: 27%;
  height: 49px;
  clip-path: polygon(100% 0, 100% 100%, 35% 0);
  z-index: -1;
}

.borderBottom--guindaClaro-punta-derecha {
  /* border-bottom: 5px solid var(--guindaClaro); */
  border-bottom: 6px solid var(--rosaClaro);
  /* border-radius: 0% 0% 12% 12%; */
}

.border-top-fac {
  border-top: thin solid #ffffff9e;
}

.PuntaDerecha {
  background: var(--main-color-vino);
  position: absolute;
  left: 99.5%;
  width: 25%;
  top: 1%;
  height: 47.5px;
  clip-path: polygon(72% 0, 0 100%, 0 0);
  z-index: 1;
}

.PuntaDerechaFondo {
  background: var(--rosaClaro);
  position: absolute;
  top: 10%;
  left: 99.79%;
  width: 27%;
  height: 49px;
  clip-path: polygon(69% 0, 0 100%, 0 0);
  z-index: -1;
}

.PuntaDerecha::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: green; */
  clip-path: polygon(72% 0, 0 100%, 0 0);
  z-index: 0;
}

/* .PuntaIzquierda {
 background-color: #6A0F49; 
 background-color: #8d949e; 
background: var(--main-color-vino);
background-color: red;
right: 100%;
position: absolute;
width: 27%;
height: 42px;
 clip-path: polygon(100% 0, 100% 100%, 71% 0); 
clip-path: polygon(100% 0, 100% 100%, 71% 0);
}

.PuntaDerecha {
 background-color: #8d949e; 
 background-color: #6A0F49; 
background: var(--main-color-vino);
background-color: red;
 clip-path: polygon(29% 0, 0 100%, 0 0); 
clip-path: polygon(29% 0, 0 100%, 0 0);
left: 100%;
position: absolute;
width: 28%;
height: 42px;
} */

/* .cajaServicios{
    top: 40%;
    width: 100%;
    align-items: end;
    display: inline;
    left: 10%;
    position: absolute;
} */

.ModalServicios {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 500px;
  background-color: transparent;
  border: solid;
  border-color: transparent;
  /* animation: move-up 2s linear */
  /* transform: scale(0.92, 0.95); */
}

.ModalServicios:focus-visible {
  outline: transparent;
}

/* 
.cajaServiciosActive {
    font-size: 13px;
    left: 30%;
} */

.FacturaValidacionActive {
  width: 78%;
  /* width: 100%; */
  left: 11%;
  position: relative;
  height: 45px;
  /* border-radius: 0% 0% 47% 47%; */
  border-bottom: 3px solid var(--rosaClaro);
  /* background-color: #8d949e; */
  background-color: var(--main-color-vino);
  /* background-color: #007bff; */
  /* background-color: #6A0F49; */
  /* border: 1px solid red; */
  cursor: pointer;
}

.PuntaIzquierdaActive {
  /* background-color: #fff; */
  background-color: var(--main-color-vino);
  /* background-color: red; */
  right: 96.8%;
  bottom: -0.1rem;
  position: absolute;
  width: 26.9%;
  height: 45px;
  clip-path: polygon(90% 0, 90% 100%, 38% 0);
  z-index: 2;
  /* transform: rotate(-1.2deg); */
  /* transform: rotate(3deg); */
}

.PuntaIzquierdaActiveRosa {
  /* background-color: #6A0F49; */
  background-color: var(--rosaClaro);
  /* background-color: red; */
  right: 99.8%;
  bottom: -0.2rem;
  position: absolute;
  width: 26.5%;
  height: 45px;
  clip-path: polygon(100% 0, 100% 100%, 46% 0%);
  z-index: 1;
  /* transform: rotate(7deg); */
}

.PuntaDerechaActive {
  background-color: var(--main-color-vino);
  /* background-color: red; */
  /* background-color: #6A0F49; */
  clip-path: polygon(68% 0, 15% 99%, 15% 0);
  left: 95.5%;
  /* ENTRE MENOS MAS BAJA */
  bottom: -0.1rem;
  position: absolute;
  width: 27%;
  height: 45px;
  /* transform: rotate(-6deg); */
  z-index: 2;
}

.PuntaDerechaActiveRosa {
  background-color: var(--rosaClaro);
  clip-path: polygon(68% 0, 15% 99%, 15% 0);
  /* clip-path: polygon(65% 0, 0 100%, 10% 0); */
  left: 96%;
  bottom: -0.2rem;
  position: absolute;
  width: 27%;
  height: 45px;
  /* transform: rotate(-5deg); */
  z-index: 1;
}

.borders-pink {
  border-left: 3px solid var(--rosaClaro);
  border-right: 3px solid var(--rosaClaro);
}

.LineaFacturaValidador {
  width: 100%;
  height: 50px;
  border-left: 3px solid var(--rosaClaro);
  border-right: 3px solid var(--rosaClaro);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 10%,
    /* Blanco en la parte superior */ rgba(202, 107, 142, 1) 40%,
    /* Tono más claro en el medio */ rgba(149, 84, 109, 1) 60%,
    /* Color intermedio */ rgba(113, 29, 69, 1) 100%
      /* Tono más oscuro en la parte inferior */
  );
  /* border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem; */
  overflow: hidden;
}

.btnLoginHeader {
  box-shadow: 1px 1px 1px 2px rgba(179, 170, 170, 0.79);
  -webkit-box-shadow: 1px 1px 1px 2px rgba(179, 170, 170, 0.79);
  -moz-box-shadow: 1px 1px 1px 2px rgba(179, 170, 170, 0.79);
}

.btnLoginHeader {
  background-color: #fff;
  color: var(--main-color-vino);
  font-weight: lighter;
  border-radius: 5rem !important;
}

.cajaServiciosActive {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

/* 
.cajaServiciosActive{
    top: 23%;
    width: 100%;
    align-items: end;
    display: inline;
    left: 13%;
    position: absolute;
} */

.itemInput:hover {
  border: 1px solid var(--guinda);
}

.paddinTopMenu {
  padding-top: 5px;
}

.footer_responsive {
  display: none !important;
}

.mensajeGobiernoNormal {
  display: block;
}

.arrowMovil {
  top: 19%;
  position: absolute;
}

.responsiveDes {
  width: max-content;
}

.letrasModalServicios {
  padding-top: 3%;
  /* font-size: 1.8rem; */
}

.sizeletrasgobTitle {
  font-size: 48px;
}

.sizeletrasgobsubtitle {
  font-size: 20px;
}

/* Radio Button */

input[type="radio"] + label {
  cursor: pointer;
}

input[type="radio"] + label:before {
  content: "";
  width: 25px;
  height: 25px;
  float: left;
  margin: 0px 10px 0 0;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 100%;
}

input[type="radio"]:checked + label {
  color: var(--guinda);
}

input[type="radio"]:checked + label:after {
  content: "";
  width: 23px;
  height: 23px;
  background-color: var(--guinda);
  border: 5px solid white;
  float: left;
  margin-left: -34px;
  margin-top: 1px;
  border-radius: 100%;
}

input[type="radio"]:checked + label::before {
  border: 8px solid var(--guinda);
}

input[type="radio"] {
  display: none;
}

/* Navidad 2023 */

.snow {
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: -100px;
}

.snow-floor {
  width: 100%;
  height: 3%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  border-radius: 50% 50% 0 0;
}

@keyframes fall {
  0% {
    top: -100px;
  }

  100% {
    top: 98vh;
  }
}

.navidad {
  display: inline-grid;
}

.gorrito-navidad {
  position: absolute;
  background-color: #d70909;
  width: 50px;
  height: 50px;
  border-radius: 100% 0px 0px 0px;
  transform: rotate(23deg);
  left: 112vh;
  top: 22vh;
}

.gorrito-navidad::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #fff;
  margin-top: -10px;
  margin-left: 22px;
  border-radius: 50%;
}

.gorrito-navidad::after {
  content: "";
  position: absolute;
  width: 62px;
  height: 11px;
  background-color: #fff;
  margin-top: 43px;
  margin-left: -32px;
  border-radius: 5px;
}

.css-1h7a9zd-MuiFormControl-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
  border-bottom: none !important ;
  /* border-color: #4b101f; */
  border-color: #8d949e;
}

.comentariosNotasExpendiente {
  resize: none;
  width: 75%;
  height: auto;
  padding: 1rem;
  border-radius: 0.5rem;
  border: thin solid rgba(68, 68, 68, 0.518);
  font-weight: normal;
  overflow: hidden;
}

/* Estilos Luis Adrian - Componente: Valoracion */
.comentarios {
  resize: none;
  width: 100%;
  height: 150px !important;
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #f7f7f7;
  font-size: 1.2rem;
  line-height: 1;
  overflow: hidden;
}

.custom-rating .MuiSvgIcon-root {
  font-size: 3rem;
}

.btn-submit {
  color: #fff;
  width: 150px;
  border-radius: 1.5rem;
  border: 0;
}

.btn-commments {
  color: #fff;
  width: 160px;
  border-radius: 1.5rem;
  border: 0;
  position: absolute;
  right: 0;
  top: 4.4rem;
}

.h2 {
  font-size: var(--step-4) !important;
}

.h3 {
  font-size: var(--step-3) !important;
}

.h4 {
  font-size: var(--step-2) !important;
}

.h4-5 {
  font-size: var(--step-1) !important;
}

.h5 {
  font-size: var(--step-0) !important;
}

.btn-commments-responsive {
  color: #fff;
  width: 200px;
  border-radius: 1.5rem;
}

.underline {
  text-decoration: underline !important;
}

.hover-btn:hover {
  color: var(--main-color-vino);
}

.valoracion-container {
  margin: 1rem auto;
  width: 90%;
}

.valoracion-container input[type="radio"] + label,
.valoracion-container input[type="radio"] + label:before,
.valoracion-container input[type="radio"]:checked + label,
.valoracion-container input[type="radio"]:checked + label:after,
.valoracion-container input[type="radio"]:checked + label::before,
.valoracion-container input[type="radio"] {
  all: unset;
}

.scroll {
  overflow-y: auto;
}

.content-valoration {
  width: 55%;
}

.img-data {
  max-height: 250px;
}

.loader-gift {
  height: 70px;
}

.color-text {
  color: var(--main-color-vino) !important;
}

.bg-gray {
  background: #e6e8eb;
}

.rounded-card {
  border-radius: 1.5rem;
}

.bg-color {
  background: var(--main-color-vino);
}

.shadow-header {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.main-background {
  background-color: var(--white-smoke);
}

.card-home {
  position: relative;
  box-shadow: 3px 3px 6px 0px var(--black-alpha-20);
  background-image: url("../assets/img/ventanilla-nuevo.png");
  background-repeat: no-repeat;
  background-position: 50% top;
  background-size: cover 100%;
  border-top-left-radius: 0;
  border-bottom-right-radius: 1.5rem;
  border-top-right-radius: 0;
  border-bottom-left-radius: 1.5rem;
  top: 5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 420px;
  width: 100%;
  position: absolute;
  padding: 2rem;
  overflow: hidden;
}

.card-home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.568);
  z-index: 1;
  border-radius: inherit;
}

.card-home * {
  position: relative;
  z-index: 2;
}

.card-home-tramite {
  box-shadow: 3px 3px 6px 0px var(--black-alpha-20);
  height: 500px !important;
  margin-top: 8rem;
}

.card-home-tramite::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.568);
  z-index: 1;
  border-radius: inherit;
}

.card-home-tramite * {
  position: relative;
  z-index: 2;
}

.loading-background {
  background-image: url("../assets/img/AtencionCiuda.png");
}

.card-actas {
  box-shadow: 3px 3px 6px 0px var(--black-alpha-20);
}

.content-info-tramite {
  height: auto;
  background-position: left;
}

.btn-back-home {
  position: static;
  left: 1rem;
  bottom: 0;
}

.content-text-home {
  margin-top: 3rem;
}

.content-tramites {
  margin-top: 14rem;
  /* height: 1480px; */
}

.acceso-funcionarios-z {
  z-index: 999;
}

.header-z {
  z-index: 997 !important;
}

.content-main-tramites {
  padding-top: 8rem;
  /* height: 100vh; */
}

.space-header-main {
  padding-top: 8rem;
}

.img-man {
  width: 100%;
}

.content-tramite {
  justify-content: center;
}

.padding-pay {
  padding: 0.35rem 0.94rem !important;
}

.padding-pay:hover {
  background-color: var(--main-color-vino) !important;
}

.axobot {
  cursor: pointer;
  height: 70px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.legend-font {
  font-size: 14px;
}

.box-t {
  height: 255px !important;
}

.bg-guinda-header {
  background-color: var(--main-color-vino);
}

.z-front {
  z-index: 999;
}

.font-open-sans {
  font-family: "Open Sans";
}

.background-fondo-home {
  background-image: url("../assets/img/fondo-home.png");
  background-size: 20%; /* Ajusta el porcentaje al tamaño que prefieras */
  background-position: center; /* Centrar la imagen */
}

.background-gray {
  background-color: #f7f7f7;
}

/* Style Search */
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 13px 13px !important;
}

.responsive-container-tramites {
  width: 100% !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.opacity-show {
  opacity: 1;
  animation: fadeIn 0.1s ease-out forwards;
}

.opacity-hide {
  opacity: 0;
  animation: fadeOut 0.1s ease-out forwards;
}

@media screen and (max-width: 480px) {
  .content-valoration {
    width: 100%;
  }

  .comentarios {
    width: 100%;
  }

  .btn-submit {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .btn-commments-responsive {
    display: none;
  }

  .w-impuesto {
    width: 75%;
  }

  .axobot:hover {
    transform: scale(1.1);
    box-shadow: none;
  }

  .card-home {
    top: 8rem;
    width: 1280px;
    background-size: 120% 120%;
  }

  .card-home-tramite {
    margin-top: 4rem;
    top: 8rem;
    width: 1280px;
    height: 520px;
    background-size: 120% 120%;
  }

  .content-tramites {
    margin-top: 12rem;
    height: auto;
  }

  .content-tramite {
    justify-content: start;
  }

  .content-info-tramite {
    background-position: center;
  }

  .btn-back-home {
    position: absolute;
    bottom: 1rem;
  }

  .content-search {
    width: 70% !important;
  }

  .content-main-tramites {
    padding-top: 9rem;
  }

  .img-man {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .content-valoration {
    width: 100%;
  }

  .comentarios {
    width: 100%;
  }

  .btn-submit {
    width: 100%;
  }

  .btn-commments {
    position: static;
  }

  .btn-commments {
    display: none;
  }
}

@media (min-width: 992px) {
  .border-left-lg {
    position: relative;
    padding-left: 10px;
  }

  .border-left-lg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(to bottom, transparent, #000000, transparent);
  }
}

@media (min-width: 850px) {
  .FacturaValidacion {
    margin-top: 1.9rem;
  }
}

@media (max-width: 850px) {
  .line-header {
    top: 4.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .custom-rating .MuiSvgIcon-root {
    font-size: 5rem;
  }

  .responsive-container-tramites {
    width: 100% !important;
  }

  .card-home-tramite {
    height: 420px !important;
    margin-top: 5rem;
  }

  .valoracion-container {
    margin: 1rem auto;
    width: 60%;
  }

  .height-responsive {
    height: 200px;
  }

  .content-tramites {
    margin-top: 7rem;
  }
  valoracion-container .content-tramites-home {
    margin-top: 0 !important;
  }

  .content-tramite {
    justify-content: center;
  }
}

.bg-main {
  background-color: var(--main-color-vino);
}

.bg-vino {
  background-color: var(--main-color-vino);
}

.size-icons {
  font-size: 18px;
}

.bg-main:hover {
  background-color: var(--rosaClaro);
}

@media screen and (min-width: 1200px) {
  .responsive-container-tramites {
    width: 80% !important;
  }
}
